import React from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import { themeJson } from "./theme";
import "../index.css";
import { json } from "./json";
import { supabase } from "../supabaseClient";  // Importa o cliente Supabase


function SurveyComponent() {
    const survey = new Model(json);
    survey.applyTheme(themeJson);

    // Quando a pesquisa for completada, os dados são enviados ao Supabase
    survey.onComplete.add(async (sender) => {
        const surveyData = sender.data;

        const { error } = await supabase
            .from('talentos_taurabots')
            .insert([
                {
                    nome_completo: surveyData["Nome completo:"],
                    matricula: surveyData["Matrícula:"],
                    curso: surveyData["Curso:"],
                    semestre: surveyData["Semestre:"],
                    idade: surveyData["Idade:"],
                    email: surveyData["question1"],
                    data_nascimento: surveyData["question30"],
                    modalidade_interesse: surveyData["Qual modalidade deseja entrar?"],
                    experiencia_com_ros: surveyData["Você tem experiência com ROS?"],
                    linguagens_programacao: surveyData["Quais linguagens de programação você domina (Python, C++, JavaScript)?"],
                    experiencia_embarcados: surveyData["Você já trabalhou com frameworks de software embarcado ou tempo real?"],
                    experiencia_sensores_atuadores: surveyData["Você tem experiência com integração de sensores e atuadores?"],
                    conhecimento_controle_sistemas: surveyData["Você tem conhecimento em controle de sistemas (PID, Kalman, controle preditivo)?"],
                    desenvolvimento_algoritmos: surveyData["Você já desenvolveu algoritmos de planejamento de trajetórias ou navegação para robôs móveis?"],
                    experiencia_projeto_robotica: surveyData["Descreva um projeto de robótica que você já trabalhou do início ao fim."],
                    participacao_competicoes: surveyData["Você já participou de competições de robótica?"],
                    experiencia_visao_computacional: surveyData["Você tem experiência com sistemas de visão computacional?"],
                    plataformas_roboticas: surveyData["Quais plataformas robóticas você já utilizou?"],
                    experiencia_motores_controladores: surveyData["Você já trabalhou com motores e controladores de motor (BLDC, Stepper, Servos)?"],
                    situacao_trabalho_em_equipe: surveyData["Descreva uma situação em que você trabalhou em equipe sob pressão."],
                    resolucao_problemas_complexos: surveyData["Como você lida com a resolução de problemas complexos em projetos de robótica?"],
                    experiencia_lideranca: surveyData["Você tem experiência em liderar equipes ou coordenar projetos?"],
                    atualizacao_conhecimento_robotica: surveyData["Como você mantém seu conhecimento atualizado nas áreas de robótica e tecnologia?"],
                    inovacao_hardware_software: surveyData["Você já trabalhou em projetos onde teve que inovar em soluções de hardware ou software?"],
                    abordagem_prototipagem: surveyData["Como você aborda a prototipagem e testes rápidos de novas ideias em projetos de robótica?"],
                    interesse_participacao_equipe: surveyData["Por que você se interessou em participar da equipe de robótica?"],
                    contato_previo_robotica: surveyData["Você já teve algum contato com robótica antes de entrar na universidade?"],
                    areas_interesse_robotica: surveyData["Quais áreas da robótica mais te interessam?"],
                    conhecimento_programacao: surveyData["Você tem algum conhecimento em programação?"],
                    interesse_aprender_programacao: surveyData["Você tem interesse em aprender linguagens de programação como Python, C++ ou JavaScript?"],
                    experiencia_eletronica: surveyData["Você tem experiência ou interesse em eletrônica básica?"],
                    trabalho_projeto_grupo: surveyData["Você já trabalhou em algum projeto em grupo?"],
                    contribuicao_para_equipe: surveyData["Como você acha que pode contribuir para uma equipe de robótica?"],
                    tipo_de_tarefa_preferida: surveyData["Você prefere trabalhar em qual tipo de tarefa?"],
                    expectativas_participacao_equipe: surveyData["Quais são suas expectativas ao participar de uma equipe de robótica?"],
                    disposicao_aprender_novas_habilidades: surveyData["Você está disposto a aprender novas habilidades e se comprometer com treinamentos oferecidos pela equipe?"],
                    experiencia_marketing: surveyData["Você já trabalhou com marketing ou tem algum conhecimento na área?"],
                    experiencia_redes_sociais: surveyData["Você tem experiência ou conhecimento em estratégias de redes sociais?"],
                    experiencia_criacao_campanhas: surveyData["Você já criou campanhas de marketing ou materiais gráficos para divulgação?"],
                    experiencia_design_grafico: surveyData["Você tem experiência com ferramentas de design gráfico (ex: Photoshop, Illustrator, Canva)?"],
                    experiencia_video_multimidia: surveyData["Você tem experiência em edição de vídeos ou criação de conteúdo multimídia?"],
                    gosto_por_comunicacao: surveyData["Você gosta de trabalhar com comunicação e divulgação de projetos?"],
                    experiencia_organizacao_eventos: surveyData["Você já participou de atividades relacionadas à organização de eventos ou planejamento de ações de marketing?"],
                    abordagens_para_engajamento: surveyData["Como você abordaria a divulgação dos projetos da equipe de robótica para aumentar o engajamento?"],
                    criatividade_marketing: surveyData["Descreva uma situação em que você precisou ser criativo para solucionar um problema de comunicação ou marketing."],
                    inovacoes_marketing: surveyData["Quais ideias inovadoras você gostaria de implementar no marketing da TauraBots?"],
                    interesse_time_marketing: surveyData["Por que você gostaria de fazer parte do time de marketing da TauraBots?"],
                    disponibilidade_horas_semanal: surveyData["Quantas horas por semana você estaria disponível para se dedicar ao time de marketing?"],
                    interesse_aprender_marketing: surveyData["Você tem interesse em aprender mais sobre marketing digital e estratégias de comunicação para equipes de tecnologia?"],
        
                }
            ]);

        if (error) {
            console.error('Erro ao salvar dados no Supabase:', error);
        } else {
            console.log('Dados salvos com sucesso!');
        }
    });

    return <Survey model={survey} />;
}

export default SurveyComponent;
