export const json = {
  "locale": "pt-br",
  "title": {
    "default": "Banco de Talentos - TauraBots",
    "pt-br": "BANCO DE TALENTOS"
  },
  "logo": {
    "pt-br": "https://api.surveyjs.io/private/Surveys/files?name=0f6db10c-135d-472b-bc41-c84ce6879463"
  },
  "pages": [
    {
      "name": "page1",
      "title": "Informações Pessoais",
      "elements": [
        {
          "type": "text",
          "name": "Nome completo:",
          "title": "Nome completo:\n",
          "isRequired": true
        },
        {
          "type": "text",
          "name": "Matrícula:",
          "title": "Matrícula:\n",
          "isRequired": true,
          "inputType": "number"
        },
        {
          "type": "text",
          "name": "Curso:",
          "title": "Curso:\n",
          "isRequired": true
        },
        {
          "type": "text",
          "name": "Semestre:",
          "title": "Semestre:\n",
          "isRequired": true,
          "inputType": "number"
        },
        {
          "type": "text",
          "name": "Idade:",
          "title": "Idade:",
          "isRequired": true,
          "inputType": "number"
        },
        {
          "type": "text",
          "name": "question1",
          "title": "Email:",
          "isRequired": true,
          "inputType": "email"
        },
        {
          "type": "text",
          "name": "question30",
          "title": {
            "pt-br": "Data de Nascimento"
          },
          "isRequired": true,
          "inputType": "date"
        },
        {
          "type": "tagbox",
          "name": "Qual modalidade deseja entrar?",
          "title": "Qual modalidade deseja entrar?",
          "isRequired": true,
          "choices": [
            "VSSS",
            "SSL",
            "Flying Robots Trial League",
            "Sumô",
            "Marketing"
          ],
          "choicesOrder": "asc",
          "maxSelectedChoices": 1
        },
        {
          "type": "html",
          "name": "SSL",
          "visible": false,
          "visibleIf": "{Qual modalidade deseja entrar?} = ['SSL']",
          "html": "<iframe width='auto' height='315' src='https://www.youtube.com/embed/ZJktf_RM2kQ?start=157' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
        },
        {
          "type": "html",
          "name": "VSSS",
          "visible": false,
          "visibleIf": "{Qual modalidade deseja entrar?} = ['VSSS']",
          "html": "<iframe  width='auto' height='315' src='https://www.youtube.com/embed/FITiGO51_HA?start=3550' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
        },
        {
          "type": "html",
          "name": "Drone",
          "visible": false,
          "visibleIf": "{Qual modalidade deseja entrar?} = ['Flying Robots Trial League']",
          "html": {
            "pt-br": "<!DOCTYPE html>\n<html lang=\"pt-br\">\n<head>\n    <meta charset=\"UTF-8\">\n    <meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\">\n    <title>Vídeo do YouTube</title>\n</head>\n<body>\n\n\n\n    <!-- Vídeo do YouTube incorporado -->\n    <iframe width=\"auto\" height=\"315\" src=\"https://www.youtube.com/embed/rhbGEru288A?start=36\" \n            title=\"YouTube video player\" \n            frameborder=\"0\" \n            allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" \n            allowfullscreen>\n    </iframe>\n  \n</body>\n</html>\n"
          }
        },
        {
          "type": "html",
          "name": "Sumô",
          "visible": false,
          "visibleIf": "{Qual modalidade deseja entrar?} = ['Sumô']",
          "html": "<iframe width='auto' height='315' src='https://www.youtube.com/embed/Lr4HlP6tQME' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
        }
      ]
    },
    {
      "name": "page2",
      "visible": false,
      "visibleIf": "{Semestre:} > 1 and {Qual modalidade deseja entrar?} = ['Flying Robots Trial League'] or {Qual modalidade deseja entrar?} = ['SSL']",
      "title": {
        "pt-br": "Habilidades Técnicas\n"
      },
      "elements": [
        {
          "type": "boolean",
          "name": "Você tem experiência com ROS?",
          "title": {
            "pt-br": "Você tem experiência com ROS?\n"
          }
        },
        {
          "type": "comment",
          "name": "Quais linguagens de programação você domina (Python, C++, JavaScript)?",
          "title": {
            "pt-br": "Quais linguagens de programação você domina (Python, C++, JavaScript)?\n"
          }
        },
        {
          "type": "comment",
          "name": "Você já trabalhou com frameworks de software embarcado ou tempo real (ex: FreeRTOS)?",
          "title": {
            "pt-br": "Você já trabalhou com frameworks de software embarcados ou em tempo real (ex: FreeRTOS)?"
          }
        },
        {
          "type": "boolean",
          "name": "question2",
          "title": {
            "pt-br": "Você tem experiência com integração de sensores e atuadores?"
          }
        },
        {
          "type": "comment",
          "name": "Você tem conhecimento em controle de sistemas (PID, Kalman, controle preditivo)?",
          "title": {
            "pt-br": "Você tem conhecimento em controle de sistemas (PID, Kalman, controle preditivo)?"
          }
        },
        {
          "type": "comment",
          "name": "Você já desenvolveu algoritmos de planejamento de trajetórias ou navegação para robôs móveis?",
          "title": {
            "pt-br": "Você já desenvolveu algoritmos de planejamento de trajetórias ou navegação para robôs móveis?"
          }
        }
      ]
    },
    {
      "name": "page3",
      "visible": false,
      "visibleIf": "{Semestre:} = 1 and {Qual modalidade deseja entrar?} = ['Flying Robots Trial League'] or {Qual modalidade deseja entrar?} = ['SSL']",
      "title": {
        "pt-br": "Experiência Prática"
      },
      "elements": [
        {
          "type": "comment",
          "name": "Descreva um projeto de robótica que você já trabalhou do início ao fim.",
          "title": {
            "pt-br": "Descreva um projeto de robótica que você já trabalhou no início ao fim."
          }
        },
        {
          "type": "comment",
          "name": "Você já participou de competições de robótica?",
          "title": {
            "pt-br": "Você já participou de competições de robótica?"
          }
        },
        {
          "type": "comment",
          "name": "Você tem experiência com sistemas de visão computacional?",
          "title": {
            "pt-br": "Você tem experiência com sistemas de visão computacional?"
          }
        },
        {
          "type": "comment",
          "name": "Quais plataformas robóticas você já utilizou?",
          "title": {
            "pt-br": "Quais plataformas robóticas você já utilizou?"
          }
        },
        {
          "type": "comment",
          "name": "Você já trabalhou com motores e controladores de motor (BLDC, Stepper, Servos)?",
          "title": {
            "pt-br": "Você já trabalhou com motores e controladores de motor (BLDC, Stepper, Servos)?"
          }
        }
      ]
    },
    {
      "name": "page4",
      "visible": false,
      "visibleIf": "{Semestre:} = 1 and {Qual modalidade deseja entrar?} = ['Flying Robots Trial League'] or {Qual modalidade deseja entrar?} = ['SSL']",
      "title": {
        "pt-br": "Soft Skills"
      },
      "elements": [
        {
          "type": "comment",
          "name": "Descreva uma situação em que você trabalhou em equipe sob pressão.",
          "title": {
            "pt-br": "Descreva uma situação em que você trabalhou em equipe sob pressão."
          }
        },
        {
          "type": "comment",
          "name": "Como você lida com a resolução de problemas complexos em projetos de robótica?",
          "title": {
            "pt-br": "Como você lida com a resolução de problemas complexos em projetos de robótica?"
          }
        },
        {
          "type": "comment",
          "name": "Você tem experiência em liderar equipes ou coordenar projetos?",
          "title": {
            "pt-br": "Você tem experiência em liderar equipes ou coordenar projetos?"
          }
        },
        {
          "type": "comment",
          "name": "Como você mantém seu conhecimento atualizado nas áreas de robótica e tecnologia?",
          "title": {
            "pt-br": "Como você mantém seu conhecimento atualizado nas áreas de robótica e tecnologia?"
          }
        }
      ]
    },
    {
      "name": "page5",
      "visible": false,
      "visibleIf": "{Semestre:} = 1 and {Qual modalidade deseja entrar?} = ['Flying Robots Trial League'] or {Qual modalidade deseja entrar?} = ['SSL']",
      "title": {
        "pt-br": "Inovação e Criatividade"
      },
      "elements": [
        {
          "type": "comment",
          "name": "question3",
          "title": {
            "pt-br": "Você já trabalhou em projetos onde teve que inovar em soluções de hardware ou software?"
          }
        },
        {
          "type": "comment",
          "name": "question4",
          "title": {
            "pt-br": "Como você aborda a prototipagem e testes rápidos de novas ideias em projetos de robótica?"
          }
        }
      ]
    },
    {
      "name": "page6",
      "visible": false,
      "visibleIf": "{Semestre:} = 1 and {Qual modalidade deseja entrar?} = ['Flying Robots Trial League'] or {Qual modalidade deseja entrar?} = ['SSL']",
      "title": {
        "pt-br": "Interesses em Robótica"
      },
      "elements": [
        {
          "type": "comment",
          "name": "question5",
          "title": {
            "pt-br": "Por que você se interessou em participar da equipe de robótica?"
          }
        },
        {
          "type": "comment",
          "name": "question6",
          "title": {
            "pt-br": "Você já teve algum contato com robótica antes de entrar na universidade?"
          }
        },
        {
          "type": "comment",
          "name": "question7",
          "title": {
            "pt-br": "Quais áreas da robótica mais te interessam?"
          }
        }
      ]
    },
    {
      "name": "page7",
      "visible": false,
      "visibleIf": "{Semestre:} > 1 and {Qual modalidade deseja entrar?} = ['Flying Robots Trial League'] or {Qual modalidade deseja entrar?} = ['SSL']",
      "title": {
        "pt-br": "Habilidades Iniciais"
      },
      "elements": [
        {
          "type": "boolean",
          "name": "question8",
          "title": {
            "pt-br": "Você tem algum conhecimento em programação?"
          }
        },
        {
          "type": "boolean",
          "name": "question9",
          "title": {
            "pt-br": "Você tem interesse em aprender linguagens de programação como Python, C++ ou JavaScript?"
          }
        },
        {
          "type": "comment",
          "name": "question10",
          "title": {
            "pt-br": "Você tem experiência ou interesse em eletrônica básica?"
          }
        }
      ]
    },
    {
      "name": "page8",
      "visible": false,
      "visibleIf": "{Semestre:} = 1 and {Qual modalidade deseja entrar?} = ['Flying Robots Trial League'] or {Qual modalidade deseja entrar?} = ['SSL']",
      "title": {
        "pt-br": "Trabalhando em Equipe"
      },
      "elements": [
        {
          "type": "comment",
          "name": "question11",
          "title": {
            "pt-br": "Você já trabalhou em algum projeto em grupo?"
          }
        },
        {
          "type": "comment",
          "name": "question12",
          "title": {
            "pt-br": "Como você acha que pode contribuir para uma equipe de robótica?"
          }
        },
        {
          "type": "comment",
          "name": "question13",
          "title": {
            "pt-br": "Você prefere trabalhar em qual tipo de tarefa?"
          }
        }
      ]
    },
    {
      "name": "page9",
      "visible": false,
      "visibleIf": "{Semestre:} = 1 and {Qual modalidade deseja entrar?} = ['Flying Robots Trial League'] or {Qual modalidade deseja entrar?} = ['SSL']",
      "title": {
        "pt-br": "Aprendizado e Desenvolvimento"
      },
      "elements": [
        {
          "type": "comment",
          "name": "question14",
          "title": {
            "pt-br": "Quais são suas expectativas ao participar de uma equipe de robótica?"
          }
        },
        {
          "type": "boolean",
          "name": "question15",
          "title": {
            "pt-br": "Você está disposto a aprender novas habilidades e se comprometer com treinamentos oferecidos pela equipe?"
          }
        }
      ]
    },
    {
      "name": "page10",
      "visible": false,
      "visibleIf": "{Semestre:} >= 1 and {Qual modalidade deseja entrar?} = ['Marketing']",
      "title": {
        "pt-br": " Habilidades e Experiência em Marketing"
      },
      "elements": [
        {
          "type": "comment",
          "name": "question16",
          "title": {
            "pt-br": "Você já trabalhou com marketing ou tem algum conhecimento na área?"
          }
        },
        {
          "type": "comment",
          "name": "question17",
          "title": {
            "pt-br": "Você tem experiência ou conhecimento em estratégias de redes sociais?"
          }
        },
        {
          "type": "comment",
          "name": "question18",
          "title": {
            "pt-br": "Você já criou campanhas de marketing ou materiais gráficos para divulgação?"
          }
        },
        {
          "type": "comment",
          "name": "question19",
          "title": {
            "pt-br": "Você tem experiência com ferramentas de design gráfico (ex: Photoshop, Illustrator, Canva)?"
          }
        },
        {
          "type": "comment",
          "name": "question20",
          "title": {
            "pt-br": "Você tem experiência em edição de vídeos ou criação de conteúdo multimídia?"
          }
        }
      ]
    },
    {
      "name": "page11",
      "visible": false,
      "visibleIf": "{Semestre:} >= 1 and {Qual modalidade deseja entrar?} = ['Marketing']",
      "title": {
        "pt-br": "Habilidades em Comunicação\n"
      },
      "elements": [
        {
          "type": "boolean",
          "name": "question21",
          "title": {
            "pt-br": "Você gosta de trabalhar com comunicação e divulgação de projetos?"
          }
        },
        {
          "type": "comment",
          "name": "question22",
          "title": {
            "pt-br": "Você já participou de atividades relacionadas à organização de eventos ou planejamento de ações de marketing?"
          }
        },
        {
          "type": "comment",
          "name": "question23",
          "title": {
            "pt-br": "Como você abordaria a divulgação dos projetos da equipe de robótica para aumentar o engajamento?"
          }
        }
      ]
    },
    {
      "name": "page12",
      "visible": false,
      "visibleIf": "{Semestre:} >= 1 and {Qual modalidade deseja entrar?} = ['Marketing']",
      "title": {
        "pt-br": "Criatividade e Inovação"
      },
      "elements": [
        {
          "type": "comment",
          "name": "question24",
          "title": {
            "pt-br": "Descreva uma situação em que você precisou ser criativo para solucionar um problema de comunicação ou marketing."
          }
        },
        {
          "type": "comment",
          "name": "question25",
          "title": {
            "pt-br": "Quais ideias inovadoras você gostaria de implementar no marketing da TauraBots?"
          }
        }
      ]
    },
    {
      "name": "page13",
      "visible": false,
      "visibleIf": "{Semestre:} >= 1 and {Qual modalidade deseja entrar?} = ['Marketing']",
      "title": {
        "pt-br": "Interesse e Disponibilidade"
      },
      "elements": [
        {
          "type": "comment",
          "name": "question26",
          "title": {
            "pt-br": "Por que você gostaria de fazer parte do time de marketing da TauraBots?"
          }
        },
        {
          "type": "comment",
          "name": "question27",
          "title": {
            "pt-br": "Quantas horas por semana você estaria disponível para se dedicar ao time de marketing?"
          }
        },
        {
          "type": "boolean",
          "name": "question28",
          "title": {
            "pt-br": "Você tem interesse em aprender mais sobre marketing digital e estratégias de comunicação para equipes de tecnologia?"
          }
        }
      ]
    }
  ]
}